<template>
  <footer>
    <div class="flex flex-wrap bg-label-color-4 p-6 mt-5 w-full">
      <p class="mt-4 md:ml-6 lg:mt-0 ml-4 text-white hover:text-white text-xs lg:ml-12 w-full text-left lg:w-1/6">
        ©{{ currentApp }} {{ currentYear }}
      </p>
      <a :href="env === 'trustus' ? 'https://www.trustus.nl/privacy-en-cookieverklaring' : 'https://synvest.nl/privacy-en-cookies'" target="_blank" class="mt-4 md:ml-6 ml-4 lg:mt-0 text-white hover:text-white text-xs w-full text-left lg:w-1/6">
        Privacy - en cookieverklaring
      </a>
      <a :href="env === 'trustus' ? 'https://www.trustus.nl/disclaimer' : 'https://synvest.nl/disclaimer'" target="_blank" class="mt-4 md:ml-6 ml-4 lg:mt-0 text-white hover:text-white text-xs w-full text-left lg:w-1/6">
        Disclaimer
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { currentEnv } from '@/plugins/variables';
import { ref } from 'vue';

const currentYear = ref(new Date().getFullYear());
const env = currentEnv;

const currentApp = env === 'trustus' ? 'Trustus' : 'Synvest';
</script>
