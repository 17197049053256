import axios, {ResponseType} from 'axios';
import { get as getCookie, removeCookie } from 'tiny-cookie';
import { BACKEND_URL } from '@/plugins/variables';
import store from '@/store';
import router from '@/router';
import { resetFlowInterface } from '@/store/flowInterface';
import { resetMainInterface } from '@/store/mainInterface';
import { deleteDatabase } from '@/db';

export const enum requestMethodType {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  OPTIONS = 'OPTIONS',
  DELETE = 'DELETE',
}

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async (error) => {
    if (error.response) {
      const { data, status } = error.response;
      const message = data.message || data?.errors?.ErrorMessage[0];
     
      store.commit('showLoading', false);
  
      if (status === 401) {
        store.commit('clearAuth', false);
        removeCookie('token');
        resetFlowInterface();
        resetMainInterface();
        await deleteDatabase();
        window.location.replace('/login');
      } else if (status === 503) {
        router.push({ path: '/maintenance' });
      } else {
        if (store.getters.showGlobalError) {
          store.commit('addNotification', { title: 'Error', message: message });
        }
      }
      return Promise.reject(error.response);
    } else {
      throw error;
    }
  }
);

type optionsType = {
  headers: object,
  auth?: boolean,
  responseType?: ResponseType,
};

const request = (
  url: string,
  method: requestMethodType,
  data: object,
  options: optionsType = { headers: {} }
) => {
  const token = getCookie('token');
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  };

  if (token && options.auth === true) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return axios({
    url: `${BACKEND_URL}${url}`,
    responseType: options.responseType ?? 'json',
    method,
    data,
    headers,
  });
};

export default request;
