import store, { StoreState } from "@/store"
import type { GetterTree, MutationTree } from "vuex"
import type { RiskProfileType } from "@/types"
import { Answer, DutchBoolean, InvestmentFrequency, RiskSelectionType, StatementType, ThirdStatementType } from "./flowInterface"
import { set } from "lodash"

type Payment = { paymentId: any; requestId: any }
type Twikey = { key: string; mandateId: string; url: string }
type FlowName = "duits" | "nederlands"

type QuestionnaireData = {
  activeQuestion: number
  answers: Answer[] | null
  firstStatement: StatementType | null
  questionEleven: DutchBoolean | null
  questions: {
    question: string // '1.1'
    answer: string // '1.1.1'
  }[]
  riskProfiles: RiskProfileType[]
  riskSelection: RiskSelectionType | null
  score: RiskProfileType | null
  scoreOne: number | null
  scoreTwo: number | null
  secondStatement: StatementType | null
  thirdStatement: ThirdStatementType | null
}

const questionnaireInitialState: QuestionnaireData = {
  activeQuestion: 0,
  answers: null,
  firstStatement: null,
  questionEleven: null,
  questions: [],
  riskProfiles: [],
  riskSelection: null,
  score: null,
  scoreOne: null,
  scoreTwo: null,
  secondStatement: null,
  thirdStatement: null,
}

export type SynvestFlowState = {
  synvestFlow: {
    currentFlow: FlowName | null
    activePage: string | null
    hasInvestment: boolean
    initialDeposit: number | null
    payment: Payment | null
    description: string | null
    questionnaire: QuestionnaireData | null
    kind: InvestmentFrequency | null
    periodicDeposit: number | null
    twikey: Twikey | null
  }
}

export const synvestFlowState: SynvestFlowState = {
  synvestFlow: {
    questionnaire: {
      ...questionnaireInitialState,
    },
    currentFlow: null,
    activePage: null,
    hasInvestment: false,
    initialDeposit: null,
    payment: null,
    description: null,
    kind: null,
    periodicDeposit: null,
    twikey: null,
  },
}

export const synvestInterfaceGetters: GetterTree<StoreState, StoreState> = {
  synvestFlow: (state) => state.synvestFlow,
  synvestFlowName: (state) => state.synvestFlow.currentFlow,
}

export const synvestInterfaceMutations: MutationTree<StoreState> = {
  resetSynvestFlow: (state): void => {
    state.synvestFlow = {
      ...synvestFlowState.synvestFlow,
      questionnaire: {
        ...questionnaireInitialState,
      },
    }
  },
  setSynvestFlowName: (state, payload: FlowName): void => {
    state.synvestFlow.currentFlow = payload
  },
  setSynvestActivePage: (state, payload: string): void => {
    state.synvestFlow.activePage = payload
  },
  setSynvestHasInvestment: (state, payload: boolean): void => {
    state.synvestFlow.hasInvestment = payload
  }
}

export const resetSynvestFlow = (): void => store.commit("resetSynvestFlow")
export const setSynvestFlow = (flow: FlowName): void => store.commit("setSynvestFlowName", flow)
export const setSynvestActivePage = (page: string): void => store.commit("setSynvestActivePage", page)
export const setSynvestHasInvestment = (hasInvestment: boolean): void => store.commit("setSynvestHasInvestment", hasInvestment)

export const getSynvestFlow = (): SynvestFlowState => store.getters.synvestFlow;
export const getSynvestActivePage = (): string => store.getters.synvestFlow.activePage;
export const getSynvestHasInvestment = (): boolean => store.getters.synvestFlow.hasInvestment;
