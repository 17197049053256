<template>
  <div
    v-if="showBanner"
    class="fixed bottom-0 left-0 w-full h-[10em] rounded-t-lg bg-white !border-2 !border-label-color-2"
  >
    <div class="cookie-wrapper w-full md:w-4/6 px-4 md:px-0 h-full flex justify-between gap-4 md:gap-0">
      <div class="flex flex-col justify-start gap-4 pt-6">
        <div class="font-semibold text-base md:text-lg">Cookies</div>
        <div>
          <p class="text-sm md:text-base">Deze applicatie maakt gebruik van cookies</p>
        </div>
      </div>
      <button
        @click="acceptCookies"
        class="bg-label-color-2 self-end text-sm md:text-base mb-4 hover:opacity-80 text-white py-2 px-4 rounded"
      >
        Sluit melding
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
const showBanner = ref(false);

onMounted(() => {
  const hasAccepted = getCookie('hasCookie');
  if (hasAccepted !== 'true') {
    showBanner.value = true;
  }
});

const acceptCookies = (): void => {
  setCookie('hasCookie', 'true', 365);
  showBanner.value = false;
};

function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
</script>

<style scoped>
@media (min-width: 768px) {
  .cookie-wrapper {
    margin-left: calc(20% + 2rem);
  }
}
</style>
