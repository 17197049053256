import { createFileStore } from '@/db/files';

const config = {
  dbName: 'db',
  dbVersion: 1,
}

function createStores(db: IDBDatabase) {
  createFileStore(db);
}

export async function getStore(storeName: string, mode: IDBTransactionMode): Promise<IDBObjectStore> {
  const db = await getDatabase();

  if (!db.objectStoreNames.contains(storeName)) {
    await deleteDatabase();
    return await getStore(storeName, mode);
  } else {
    const tx = db.transaction(storeName, mode);
    return tx.objectStore(storeName);
  }
}

export async function clearStore(storeName: string) {
  const store = await getStore(storeName, 'readwrite');
  const request = store.clear();

  return new Promise<void>(resolve => {
    request.onsuccess = event => resolve();
  });
}

function getDatabase() {
  const request = window.indexedDB.open(config.dbName, config.dbVersion);

  return new Promise<IDBDatabase>(resolve => {
    request.onupgradeneeded = event => {
      if (!(event.target instanceof IDBOpenDBRequest)) return;
      const db = event.target.result;
      createStores(db);
      resolve(db);
    }

    request.onsuccess = event => {
      if (!(event.target instanceof IDBOpenDBRequest)) return;
      resolve(event.target.result);
    };
  });
}

export function deleteDatabase() {
  const request = window.indexedDB.deleteDatabase(config.dbName);

  return new Promise<void>(resolve => {
    request.onsuccess = event => resolve();
  });
}
