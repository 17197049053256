import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { currentEnv } from '@/plugins/variables';

const env = currentEnv;

const openDomainPaths = [
  '/open/berekenen-bedrag-beleggen',
  '/open/berekenen-lijfrente-opbouwen',
  '/open/berekenen-lijfrente-uitkeren',
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: () => {
      if (store.getters.isAuthenticated) {
        return env === 'trustus' ? '/mijn-investeringen' : '/mijn-vastgoed';
      } else {
        return '/login';
      }
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/TestView.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Admin/AdminView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    path: '/twikey-result',
    name: 'twikey-result',
    meta: { requiresAuth: true },
    component: () => import('@/views/TwikeyResultView.vue'),
  },
  {
    path: '/ideal-result',
    name: 'ideal-result',
    meta: { requiresAuth: true },
    component: () => import('@/views/IdealResultView.vue'),
  },
  /* Synvest routes */
  {
    path: '/mijn-vastgoed',
    name: 'mijn-vastgoed',
    meta: { requiresAuth: true },
    /* TODO: Add actual correct component */
    component: () => import('@/views/MijnInvesteringen/MijnInvesteringenView.vue'),
  },
  {
    path: '/nederlands-vastgoed',
    name: 'nederlands-vastgoed',
    meta: { requiresAuth: true },
    /* TODO: Add actual correct component */
    component: () => import('@/views/Synvest/BeleggingToevoegenMaster.vue'),
  },
  {
    path: '/duits-vastgoed',
    name: 'duits-vastgoed',
    meta: { requiresAuth: true },
    /* TODO: Add actual correct component */
    component: () => import('@/views/Synvest/BeleggingToevoegenMaster.vue'),
  },
  /* Trustus routes */
  {
    path: '/mijn-investeringen',
    name: 'mijn-investeringen',
    meta: { requiresAuth: true },
    component: () => import('@/views/MijnInvesteringen/MijnInvesteringenView.vue'),
  },
  {
    path: '/betaling-afronden',
    name: 'betaling-afronden',
    meta: { requiresAuth: true },
    component: () => import('@/views/BetalingAfronden/BetalingAfrondenView.vue'),
  },
  {
    path: '/mijn-berekeningen',
    name: 'mijn-berekeningen',
    meta: { requiresAuth: true },
    component: () => import('@/views/MijnBerekeningen/MijnBerekeningenView.vue'),
  },
  {
    path: '/mijn-instellingen/wachtwoord',
    name: 'mijn-instellingen-wachtwoord',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/MijnInstellingen/WatchwoordWijzigenView.vue'),
  },
  {
    path: '/mijn-instellingen/overige-relaties',
    name: 'mijn-instellingen-overige-relaties',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/MijnInstellingen/OverigeRelatiesView.vue'),
  },
  {
    path: '/mijn-instellingen/persoonlijke-informatie',
    name: 'mijn-instellingen-persoonlijke-informatie',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/MijnInstellingen/PersoonlijkeInformatie.vue'),
  },
  {
    path: '/contractbeheer/:contractId/transacties',
    name: 'contractbeheer-transacties',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/TransactiesView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/rendement',
    name: 'contractbeheer-rendement',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/RendementView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/gegevens',
    name: 'contractbeheer-gegevens',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/GegevensView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/wijzigen/:option?',
    name: 'contractbeheer-wijzigen',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/WijzigenView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/historie',
    name: 'contractbeheer-historie',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/HistorieView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/documenten',
    name: 'contractbeheer-documenten',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/DocumentenView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/verkocht',
    name: 'contractbeheer-verkocht',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/VerkochtView.vue'),
  },
  {
    path: '/contractbeheer/:contractId/bijstorten',
    name: 'contractbeheer-bijstorten',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/Contractbeheer/BijstortenView.vue'),
  },
  {
    path: '/mijn-instellingen/nieuwsbrief',
    name: 'mijn-instellingen-nieuwsbrief',
    meta: {
      requiresAuth: true,
      hideLeftMenu: true,
    },
    component: () => import('@/views/MijnInstellingen/NieuwsbriefView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/bedrag-beleggen',
    name: 'bedrag-beleggen',
    meta: { requiresAuth: true },
    component: () => import('@/views/Flows/BedragBeleggen/index.vue'),
  },
  {
    path: '/lijfrente-opbouwen',
    name: 'lijfrente-opbouwen',
    meta: { requiresAuth: true },
    component: () => import('@/views/Flows/LijfrenteOpbouwen/index.vue'),
  },
  {
    path: '/lijfrente-uitkeren',
    name: 'lijfrente-uitkeren',
    meta: { requiresAuth: true },
    component: () => import('@/views/Flows/LijfrenteUitkeren/index.vue'),
  },
  {
    path: '/open/berekenen-bedrag-beleggen',
    name: 'open-bedrag-beleggen',
    meta: { requiresAuth: false },
    component: () => import('@/views/Open/OpenBedragBeleggenMaster.vue'),
  },
  {
    path: '/open/berekenen-lijfrente-opbouwen',
    name: 'open-lijfrente-opbouwen',
    meta: { requiresAuth: false },
    component: () => import('@/views/Open/OpenLijfrenteOpbouwenMaster.vue'),
  },
  {
    path: '/open/berekenen-lijfrente-uitkeren',
    name: 'open-lijfrente-uitkeren',
    meta: { requiresAuth: false },
    component: () => import('@/views/Open/OpenLijfrenteUitkerenMaster.vue'),
  },
  {
    path: '/account-aanmaken',
    name: 'account-aanmaken',
    component: () => import('@/views/Account/CreateAccount.vue'),
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'wachtwoord-vergeten',
    component: () => import('@/views/Account/ForgotPassword.vue'),
  },
  {
    path: '/wachtwoord-reset',
    name: 'wachtwoord-reset',
    component: () => import('@/views/Account/ResetPassword.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/mijn-investeringen',
  },
  {
    path: '/maintenance',
    name: 'MaintenancePage',
    meta: {
      requiresAuth: false,
      hideLeftMenu: true,
    },
    component: () => import('@/components/Error/MaintenancePage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, from, next) {
  if (to.name === 'MaintenancePage') {
    return next(); // handling 503
  }
  if (store.getters?.currentUser?.role !== 'admin' && to.path.startsWith("/admin")) {
    return next({ path: '/mijn-investeringen' });
  }
  if (openDomainPaths.includes(to.path) && env !== 'trustus') {
    // Restrict synvest routes to trustus open domain routes
    return next({ path: '/login' });
  }
  if (to.path === '/login' && store.getters.isAuthenticated) {
    return next({ path: '/mijn-investeringen' });
  }
  if (
    (to.path === '/mijn-investeringen' || (to.meta && to.meta.requiresAuth)) &&
    !store.getters.isAuthenticated
  ) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
      params: {},
      replace: true,
    });
  } else {
    return next();
  }
});

export default router;

