type FaviconSet = Record<string, string>;

interface Favicons {
  Synvest: FaviconSet;
  Trustus: FaviconSet;
}

type BrandName = keyof Favicons | null;


function setFavicons() {
  const brand: BrandName = window.customEnv ? window.customEnv.current_env : null;
  
  const favicons: Favicons = {
    Synvest: {
      favicon: '/icons/synvest/favicon.ico',
      appleTouchIcon: '/icons/synvest/apple-touch-icon.png',
      favicon16: '/icons/synvest/favicon-16x16.png',
      androidChrome192: '/icons/synvest/android-chrome-192x192.png',
      androidChrome512: '/icons/synvest/android-chrome-512x512.png',
      siteManifest: '/icons/synvest/site.webmanifest',
    },
    Trustus: {
      favicon: '/icons/trustus/favicon.ico',
      appleTouchIcon: '/icons/trustus/apple-touch-icon.png',
      favicon16: '/icons/trustus/favicon-16x16.png',
      androidChrome192: '/icons/trustus/android-chrome-192x192.png',
      androidChrome512: '/icons/trustus/android-chrome-512x512.png',
      siteManifest: '/icons/trustus/site.webmanifest',
    }
  };

  const selectedFavicons: FaviconSet | null = brand && favicons[brand] ? favicons[brand] : null;

  const updateFaviconAttribute = (id: string, attributeValue: string) => {
    const element = document.getElementById(id);
    if (element) { 
      element.setAttribute('href', attributeValue);
    } else {
      return;
    }
  };

  if (selectedFavicons) {
    updateFaviconAttribute('favicon', selectedFavicons.favicon);
    updateFaviconAttribute('apple-touch-icon', selectedFavicons.appleTouchIcon);
    updateFaviconAttribute('favicon-16x16', selectedFavicons.favicon16);
    updateFaviconAttribute('android-chrome-192x192', selectedFavicons.androidChrome192);
    updateFaviconAttribute('android-chrome-512x512', selectedFavicons.androidChrome512);
    updateFaviconAttribute('web-manifest', selectedFavicons.siteManifest);
  }
}

if (window.customEnv) {
  setFavicons();
}

document.addEventListener("customEnvReady", setFavicons);
