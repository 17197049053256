import { computed } from 'vue';
import { useRoute } from 'vue-router';

type Menu = {
  name: string;
  items: {
    name: string;
    route: string;
    active?: boolean;
  }[];
};

export default function synMenuItems() {

  const synvestMenu = computed(() => {
    const currentRoute = useRoute().path;
    
    const items: Menu[] = [
      {
        name: 'Mijn overzicht',
        items: [
          {
            name: 'Mijn vastgoed',
            route: '/mijn-vastgoed',
          },
          {
            name: 'Betaling afronden',
            route: '/betaling-afronden',
          },
        ]
      },
      {
        name: 'Producten toevoegen',
        items: [
          {
            name: 'Nederlands vastgoed',
            route: '/nederlands-vastgoed',
          },
          {
            name: 'Duits vastgoed',
            route: '/duits-vastgoed',
          },
        ]
      },
    ];

    items.forEach(menu => {
      menu.items.forEach(item => {
        item.active = currentRoute.startsWith(item.route);
      });
    });

    return items;
  });

  return { synvestMenu };
}
